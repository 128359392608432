import React from 'react'

const ForHer = () => {
  return (
    <div><h1 style={{color:"#FF566C",fontSize:"24px",fontWeight:600,margin:"1rem 0 0 1rem"}}>Meet John Doe</h1>
    <div className='white-image'>
    <img src="" alt="" />
    </div>
    <p style={{color:"white",fontSize:"15px",margin:"1rem"}}>with a wonderful career in web development she became very famous for her co2 emission reduction thing which anyone barely know but still since she’s a women she deserves credit</p>
    <h1 style={{color:"#FF566C",fontSize:"24px",fontWeight:600,margin:"1rem 0 0 1rem"}}>Stores</h1>
    <div className='flex-box-2'>
      <div className='flex-box'></div>
      <div className='flex-box'></div>
      <div className='flex-box'></div>
      <div className='flex-box'></div>
    </div>
    </div>
  )
}

export default ForHer